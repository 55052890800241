import {useRouteQuery} from '@vueuse/router'
import {parseSortDir, serializeGeoSearch, deserializeGeoSearch} from "@/utils/parseTool.js";
import {useSearchQueryStore} from "~/stores/searchQuery";


export function useQueryFormatter() {
    const typeCategoryId = import.meta.env.VITE_APP_BASE_TYPE_CATEGORY_ID
    const usageCategoryId = import.meta.env.VITE_APP_BASE_USAGE_CATEGORY_ID
    const tagCategoryId = import.meta.env.VITE_APP_BASE_TAG_CATEGORY_ID
    const searchQueryStore = useSearchQueryStore()


    const _citySelectedId = useRouteQuery('_citySelectedId', 0, {transform: Number})
    const _typeCategoryId = useRouteQuery('_typeCategoryId', null, {
        transform: (value) => {
            if (value > 0) {
                return Number(value)
            } else {
                return null
            }
        }
    })
    const _usageCategoryId = useRouteQuery(
        '_usageCategoryId',
        [], // 預設值為空陣列
        {
            transform: (val) => {
                if (!val) {
                    return [];
                }

                // 如果是數字陣列，直接返回
                if (Array.isArray(val)) {
                    return val.map(item => Number(item)).filter(n => !isNaN(n));
                }

                // 如果是逗號分隔的字串，轉換為數字陣列
                if (typeof val === 'string') {
                    return val.split(',')
                        .map(item => Number(item))
                        .filter(n => !isNaN(n));
                }

                // 對其他情況進行處理，預設返回空陣列
                return [];
            }
        }
    );

    const _tagCategoryId = useRouteQuery(
        '_tagCategoryId',
        [],
        {
            transform: (val) => {
                if (!val) {
                    return [];
                }

                // 如果是數字陣列，直接返回
                if (Array.isArray(val)) {
                    return val.map(item => Number(item)).filter(n => !isNaN(n));
                }

                // 如果是逗號分隔的字串，轉換為數字陣列
                if (typeof val === 'string') {
                    return val.split(',')
                        .map(item => Number(item))
                        .filter(n => !isNaN(n));
                }

                // 對其他情況進行處理，預設返回空陣列
                return [];
            }
        }
    );
    const _searchDate = useRouteQuery('_searchDate')
    const _searchStartTime = useRouteQuery('_searchStartTime')
    const _searchEndTime = useRouteQuery('_searchEndTime')
    const _isAllDay = useRouteQuery('_isAllDay', 0, {transform: Number})
    const _keyword = useRouteQuery('_keyword', '')

    const _equipmentList = useRouteQuery('_equipmentList', [],
        {transform: (value) => value.map((v) => Number(v))})
    const _serviceList = useRouteQuery('_serviceList', [],
        {transform: (value) => value.map((v) => Number(v))})

    const _currentPage = useRouteQuery('_currentPage', 1, {transform: Number})
    const _sort = useRouteQuery('_sort')
    const _dir = useRouteQuery('_dir')
    const _requestId = useRouteQuery('_requestId', '')


    const _geoSearch = useRouteQuery('geocodes', null);


    /* watch(_keyword, (newKeyword) => {

         _citySelectedId.value = null
         _typeCategoryId.value = null
         _usageCategoryId.value = null
         _tagCategoryId.value = null
         _searchDate.value = null
         _searchStartTime.value = null
         _searchEndTime.value = null
         _isAllDay.value = null
         _equipmentList.value = []
         _serviceList.value = []
         _currentPage.value = 1
         _sortDir.value = null
         _requestId.value = ''
     })

 */

    /* function initQueryDate() {
         _citySelectedId.value = null
         _typeCategoryId.value = null
         _usageCategoryId.value = null
         _tagCategoryId.value = null
         _searchDate.value = null
         _searchStartTime.value = null
         _searchEndTime.value = null
         _isAllDay.value = null
         _keyword.value = null
         _equipmentList.value = []
         _serviceList.value = []
         _currentPage.value = 1
         _sortDir.value = null
     }

     function initGeoSearch() {
         // todo 要清除跟 geo_search 有關的資料
         /!*
         *  geo_search: {
                     longitude: 0,
                     latitude: 0,
                     width: 0,
                     height: 0,
                     *
                     *
                     *     pagination.sort: ''
                 },*!/

     }

     function clearKeyword() {
         _keyword.value = null
     }

     function setSelectedCategoryObject(categorySelectObj) {
         _typeCategoryId.value = categorySelectObj[Number(typeCategoryId)] || null;
         _usageCategoryId.value = categorySelectObj[Number(usageCategoryId)] || null;
         _tagCategoryId.value = categorySelectObj[Number(tagCategoryId)] || null;
     }


     function formatQueryData() {

         const queryData = {
             pagination: {
                 dir: 1,
                 limit: 20,
                 page: 1,
                 sort: ''
             },
             filter_options: {
                 keyword: '',
                 rent_time: {
                     rent_type: 0,
                     begin_time: '',
                     end_time: ''
                 },
                 geo_search: {
                     longitude: 0,
                     latitude: 0,
                     width: 0,
                     height: 0,
                     radius: 0
                 },
                 equipment_ids: [],
                 service_ids: [],
                 city_ids: [],
                 category_ids: {}
             },
             request_id: ''
         }


         if (typeof _typeCategoryId.value === 'string') {
             _typeCategoryId.value = [_typeCategoryId.value]
         }
         if (typeof _usageCategoryId.value === 'string') {
             _typeCategoryId.value = [_usageCategoryId.value]
         }
         if (typeof _tagCategoryId.value === 'string') {
             _typeCategoryId.value = [_tagCategoryId.value]
         }
         queryData.filter_options.city_ids = _citySelectedId.value ? _citySelectedId.value.split(',').map(Number) : []
         queryData.filter_options.category_ids[typeCategoryId] = _typeCategoryId.value ? _typeCategoryId.value.map(Number) : []
         queryData.filter_options.category_ids[usageCategoryId] = _usageCategoryId.value ? _usageCategoryId.value.map(Number) : []
         queryData.filter_options.category_ids[tagCategoryId] = _tagCategoryId.value ? _tagCategoryId.value.map(Number) : []

         const date = _searchDate.value;
         const beginTime = _isAllDay.value || !_searchStartTime.value ? '00:00:00' : _searchStartTime.value + ':00'
         const endTime = _isAllDay.value || !_searchEndTime.value ? '23:59:59' : _searchEndTime.value + ':00'

         queryData.filter_options.rent_time = {
             rent_type: _isAllDay.value ? 1 : 0,
             begin_time: date ? `${date} ${beginTime}` : '',
             end_time: date ? `${date} ${endTime}` : ''
         }

         queryData.filter_options.keyword = _keyword.value ? _keyword.value as string : ''

         queryData.filter_options.equipment_ids = _equipmentList.value
         queryData.filter_options.service_ids = _serviceList.value

         queryData.pagination.page = _currentPage.value

         if (_sortDir.value) {
             const {sort, dir} = parseSortDir(_sortDir.value);
             queryData.pagination.sort = sort;
             queryData.pagination.dir = dir;
         }

         if (_requestId.value) {
             queryData.request_id = _requestId.value
         }


         return queryData
     }


     function delUsageCategoryId() {
         _usageCategoryId.value = null
     }*/

    function setSearchQueryToRoute(queryData) {
        // todo 設定搜尋條件到路由
        _citySelectedId.value = queryData.filter_options.city_ids
        _typeCategoryId.value = queryData.filter_options.category_ids[typeCategoryId]
        _usageCategoryId.value = queryData.filter_options.category_ids[usageCategoryId]
        _tagCategoryId.value = queryData.filter_options.category_ids[tagCategoryId]
        _searchDate.value = queryData.filter_options.rent_time.begin_time ? queryData.filter_options.rent_time.begin_time.split(' ')[0] : null
        _searchStartTime.value = queryData.filter_options.rent_time.begin_time ? queryData.filter_options.rent_time.begin_time.split(' ')[1] : null
        _searchEndTime.value = queryData.filter_options.rent_time.end_time ? queryData.filter_options.rent_time.end_time.split(' ')[1] : null
        _isAllDay.value = queryData.filter_options.rent_time.rent_type
        _keyword.value = queryData.filter_options.keyword
        _equipmentList.value = queryData.filter_options.equipment_ids
        _serviceList.value = queryData.filter_options.service_ids
        _currentPage.value = queryData.pagination.page
        _sort.value = queryData.pagination.sort ? `${queryData.pagination.sort}` : null
        _dir.value = queryData.pagination.dir ? `${queryData.pagination.dir}` : null
        _requestId.value = queryData.request_id


        _geoSearch.value = serializeGeoSearch(queryData.filter_options.geo_search)


    }


    function setSearchQueryRouteToStore(routeQuery): void {

        _citySelectedId.value && searchQueryStore.setSelectedCityID([_citySelectedId.value])

        _typeCategoryId.value && searchQueryStore.setSelectedCategoryObject({
            [typeCategoryId]: [_typeCategoryId.value].map(Number)
        })

        _usageCategoryId.value && searchQueryStore.setSelectedCategoryObject({
            [usageCategoryId]: _usageCategoryId.value
        })



        _geoSearch.value && searchQueryStore.setGeoSearch(deserializeGeoSearch(_geoSearch.value))

        const pagination = {
            page: _currentPage.value,
            sort: _sort.value ? parseSortDir(_sort.value).sort : 'created_date',
            dir: _dir.value ? parseSortDir(_dir.value).dir : 1
        }

        searchQueryStore.setPagination(pagination)


        _requestId.value && searchQueryStore.setRequestId(_requestId.value)

        const date = _searchDate.value;
        const beginTime = _isAllDay.value || !_searchStartTime.value ? '00:00:00' : _searchStartTime.value
        const endTime = _isAllDay.value || !_searchEndTime.value ? '23:59:59' : _searchEndTime.value
        searchQueryStore.setRentTimeSelected({
            rent_type: _isAllDay.value,
            begin_time: date ? `${date} ${beginTime}` : '',
            end_time: date ? `${date} ${endTime}` : ''
        })

        _keyword.value && searchQueryStore.setKeyword(_keyword.value)


        _tagCategoryId.value && searchQueryStore.setSelectedCategoryObject({
            [tagCategoryId]: _tagCategoryId.value
        })
        _equipmentList.value && searchQueryStore.setEquipmentIds(_equipmentList.value)
        _serviceList.value && searchQueryStore.setServiceIds(_serviceList.value)



    }

    return {
        setSearchQueryToRoute,
        setSearchQueryRouteToStore
    }


}
